import React, { useEffect, useRef } from "react";
import { Link } from 'gatsby';
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { CONSTANTS } from '@Constants/Constants';
import { activatePill } from '../../utils';

function handleAnimation() {
    const sectionTl = gsap.timeline({
        scrollTrigger: {
            trigger: ".charge-knowledge",  
            start: "top top",
            end: "+=120%",
            scrub: 0.23,
            pin: true,
        },
    })

    sectionTl.fromTo(".knowledge_heading", {opacity: 0, y: '60px'}, {opacity: 1, y: '0px', duration: 1})
    .fromTo(".knowledge_item-1", {opacity: 0, y: '60px'}, {opacity: 1, y: '0px', delay: 1, duration: 1})
    .fromTo(".knowledge_item-2", {opacity: 0, y: '60px'}, {opacity: 1, y: '0px', delay: -1, duration: 1})
    .fromTo(".knowledge_btn", {opacity: 0, y: '60px'}, {opacity: 1, y: '0px', duration: 1});
}

export default function ChargeKnowledge() {
    const article1 = useRef(null);
    const article2 = useRef(null);
    const article3 = useRef(null);
    const article4 = useRef(null);
    
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        gsap.timeline({
            scrollTrigger: {
                trigger: '.charge-knowledge',
                start: 'top top',
                onEnter: () => {
                    activatePill(CONSTANTS.section.chargers.id)
                },
                onEnterBack: () => {
                    activatePill(CONSTANTS.section.chargers.id)
                }
            }
        })
        
        ScrollTrigger.matchMedia({
            "(max-width: 768px)": function() {
                const articles = [article1.current, article2.current, article3.current, article4.current];
                articles.forEach(article => {
                    if (article.textContent.length > 150) {
                        article.textContent = article.textContent.substring(0, 150) + '...';
                        }
                });
            },
            "(min-width: 1025px)": function() {
                handleAnimation();
            }
        });
    }, [])

    return (
        <>
            <section className="fullscreen charge-knowledge observe" data-section-type={CONSTANTS.sectionType.dark}>
                <img className="charge-knowledge_bg"
                    src="/images/tinified/charge-knowledge.jpg"
                    loading="lazy"
                />
                <div className="container">
                    <div className="text-white charge-knowledge_content">
                        <h2 className="knowledge_heading">Doładuj się wiedzą</h2>
                        <div className="knowledge">
                            <div className="knowledge_item knowledge_item-1">
                                <h3>Elektromobilność</h3>
                                <p ref={article1}>Infrastruktura ładowania, innowacyjne pojazdy na energię elektryczną czy zachodzące zmiany prawne - to wszystko wiąże się z elektromobilnością. </p>
                            </div>
                            <div className="knowledge_item knowledge_item-1">
                                <h3>Transformacja energetyczna</h3>
                                <p ref={article2}>Jej cel to wzmocnienie efektywności energetycznej, budowa zrównoważonej gospodarki oraz całkowite przejście na odnawialne źródła energii. </p>
                            </div>
                            <div className="knowledge_item knowledge_item-2">
                                <h3>Energetyka rozproszona</h3>
                                <p ref={article3}>Masz własny pojazd elektryczny, magazyn energii i instalacje fotowoltaiczną? W takim razie już częściowo korzystasz z energii w systemie rozproszonym.</p>
                            </div>
                            <div className="knowledge_item knowledge_item-2">
                                <h3>Neutralność emisyjna</h3>
                                <p ref={article4}>To równowaga pomiędzy emitowaną do atmosfery ilością dwutlenku węgla, a jego pochłanianiem. Z pojazdem elektrycznym zmniejszasz swój ślad węglowy.</p>
                            </div>
                        </div>
                        <button className="btn btn--primary knowledge_btn knowledge_item" title="Doładuj się wiedzą">
                            <a href="https://swiatoze.pl/" target="_blank">Doładuj się wiedzą</a>
                        </button>
                    </div>
                </div>
            </section>
        </>
    )
}